import {
  createCoreClient,
  createTrackingClient,
  setupHttp,
} from '@mentimeter/http-clients';
import { userCache } from '@mentimeter/user';

const isServer = typeof window === 'undefined';

const token = isServer ? undefined : userCache.getToken();
const trackingToken = isServer ? undefined : userCache.getTrackingToken();
const defaultRegion = userCache?.region ?? 'eu';

function setup() {}

setupHttp({
  serviceName: process.env.NEXT_PUBLIC_SERVICE_NAME,
  core: {
    defaultRegion,
    baseUrls: {
      eu: process.env.NEXT_PUBLIC_EU_CORE_URL,
      us: process.env.NEXT_PUBLIC_US_CORE_URL,
    },
    createClient(baseURL) {
      return createCoreClient({ baseURL, timeout: 40000, token });
    },
  },
  tracking: {
    defaultRegion,
    baseUrls: {
      eu: process.env.NEXT_PUBLIC_EU_TRACKING_URL,
      us: process.env.NEXT_PUBLIC_US_TRACKING_URL,
    },
    createClient(baseURL) {
      return createTrackingClient({
        baseURL,
        token: trackingToken,
        timeout: 40000,
      });
    },
  },
});

export default setup;
